.pagination li{
    padding: 0px 10px;
    cursor: pointer;
    margin: 0 5px;
    font-size: 16px;
    line-height: 27px;
    height: 25px;
}
.selected-page, .selected-page:hover{
    background-color: var(--rm-bg);
    color: #fff;
    padding: 0px 10px;
    border-radius: 5px;
}

.pagination li:hover{
    background-color: rgb(218, 214, 214);
    border-radius: 5px;
}
.content-box{
    min-height: 500px;
}
.pagination{
    margin: 50px 0 0 0 !important;
  }