.header-middle{
    background: linear-gradient(90deg, #00ffac 35%, #0954ff);
    // background: #e3ab10;
    box-shadow: 0 3px 8px #0000003d;
    color: #222;
    font-size: 1.2rem;
    font-weight: 700;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
}
.logo{
    width:160px;
}
.container{
    padding:0 !important;
}
.login-btn a{
    color: #ffffff;
    font-weight: normal;
}
.login-btn a:hover{
    color: #ffffff;
    font-weight: normal;
}