.login-outbox{
  height: 100vh;
 }

.login-wrappere {
  background: #fff;
  padding: 25px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.login-with-otp{
  text-align: center;
  font-size: 14px;
  margin: 10px 0;
}
.log_cap{
  text-align: center;
  font-size: 18px;
  margin-bottom: 35px;
  width: 100%;
  display: block;
}
.left{
  
}
.login-form-button{
  background: var(--theme-primary-background);
  color: var(--font-white);
}

.login-form-forgot{
  color: #000;
}
.ant-table-wrapper .ant-table{
  color: #000000;
}
.ant-form-item{
  width: 100%;
  margin: 10px 0;
}
.left{
  width: 350px;
}
.right{
  width: 350px;
}
.login-wrappere h2{
  font-size: 20px;
  text-align: center;
  border-bottom:#f5f2e5 solid 1px;
  padding:0 0 15px 0;
  margin:0 0 15px 0;
}
